import "../scss/style.scss";

const CarouselItemsCount = 8;
let carouselPosition = 0;
let carouselStep = 380; //px
let carouselMaxStepDesktop = 4;
let carouselWidthDesktop = 1530; //px

const carouselSlide = (event) => {
    let carousel = document.getElementById("staff-carousel")
    if (event.target.id === "carousel-slide-left") {
        if (carousel.parentElement.clientWidth === carouselWidthDesktop) {
            if (carouselPosition < 0) {
                carouselPosition += carouselStep;
                carousel.style.transform = `translateX(${carouselPosition}px)`;
            }
        }
    }
    if (event.target.id === "carousel-slide-right") {
        if (carousel.parentElement.clientWidth === carouselWidthDesktop) {
            if (carouselPosition > -carouselStep*carouselMaxStepDesktop) {
                carouselPosition -= carouselStep;
                carousel.style.transform = `translateX(${carouselPosition}px)`;
            }
        }
    }
}

const handleScroll = () => {
    let scrollToTopBtn = document.getElementById("scroll-up-btn");
    let rootElement = document.documentElement;
    let scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
    if ((rootElement.scrollTop / scrollTotal ) > 0.20 ) {
        scrollToTopBtn.classList.remove("hidden");
    } else {
        scrollToTopBtn.classList.add("hidden");
    }
}

const setBasicEvents = () => {

    document.querySelectorAll("video").forEach(item => {
            item.addEventListener("click",
                event => {
                    event.preventDefault();
                    if (event.target.paused) {  // если видео остановлено, запускаем
                        event.target.play();
                        event.target.controls = true;
                        document.querySelectorAll("video").forEach(elem => {
                                if (elem !== event.target) {
                                    elem.pause();
                                }
                            }
                        );
                    } else {
                        event.target.pause();
                    }
                });
            item.addEventListener("play", event => {
                document.querySelectorAll("video").forEach(elem => {
                        if (elem !== event.target) {
                            elem.pause();
                        }
                    }
                );
            });
        }
    );

    document.querySelectorAll(".products__link").forEach(item => {
        item.addEventListener("click", (event) => {
            event.preventDefault();
            let href = event.target.closest(".products__link").href;
            let id = href.slice(href.indexOf('#')+1);
            let productElement = document.getElementById(id);
            productElement.classList.remove("hidden");
            productElement.parentElement.classList.remove("hidden");
            document.querySelectorAll(".products__description").forEach(item => {
                if (item !== productElement.parentElement) {
                    item.classList.add("hidden");
                }
            });
            productElement.classList.add("product--outlined");
            productElement.parentElement.querySelectorAll(".product").forEach(item => {
                if (item !== productElement) {
                    item.classList.remove("product--outlined");
                    item.classList.add("hidden");
                }
            });
            productElement.scrollIntoView({
                behavior: 'smooth'
            });

            });
        });

    document.querySelectorAll(".products__show-btn-content").forEach(item => {
        item.addEventListener("click", event => {
            event.preventDefault();
            let btn = event.target;
            if (btn.id === "") {
                btn = event.target.parentElement;
            }
            let visibleTab = document.getElementById(`tab-${btn.id}`);
            visibleTab.classList.remove("hidden");
            
            document.querySelectorAll(".products__description").forEach(item => {
                if (item.id !== `tab-${btn.id}`) {
                    if (!item.classList.contains("hidden")) {
                        item.classList.add("hidden");
                    }
                }
            });

            visibleTab.querySelectorAll(".product").forEach(item => {
                item.classList.remove("product--outlined");
                item.classList.remove("hidden");
            })

            visibleTab.scrollIntoView({
                behavior: 'smooth'
            });

        });
    });

    document.querySelectorAll(".faq__question-btn").forEach(item => {
        item.addEventListener("click", event => {
            event.preventDefault();
            let btn = event.target;
            if (btn.style.transform === "") {
                btn.style.transform = "rotate(180deg)";
            } else {
                btn.style.transform = "";
            }

            document.getElementById(`answer-to-${btn.id}`).classList.toggle("hidden");
        })
    });



    document.getElementById("carousel-slide-left").addEventListener("click", carouselSlide);
    document.getElementById("carousel-slide-right").addEventListener("click", carouselSlide);

    document.addEventListener("scroll", handleScroll);

    document.getElementById("scroll-up-btn").addEventListener("click", (event) => {
        document.getElementById("products").scrollIntoView({
            behavior: 'smooth'
        });
    });

}

window.onload = setBasicEvents;